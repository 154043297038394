<template>
  <b-modal
    id="setup-status-tags"
    ref="setup-status-tags"
    centered
    :title="title"
    ok-title="Save changes"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="setModalData"
    @ok="postStatusTags"
    @hidden="resetModalData"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Selected Tags "
          >
            <b-form-tag
              v-for="tag in selectedTags"
              :key="tag.id"
              class="align-content-center mb-1"
              style="marginRight: 5px"
              :style="{
                backgroundColor: '#F3F2F7',
                color: tag.color,
              }"
              @remove="removeTag(tag)"
            >
              {{ tag.name }}
            </b-form-tag>
          </b-form-group>
        </b-col>

        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Select Tag Type"
          >
            <v-select
              v-model="selectedTagSet"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tagSetsList"
              :clearable="false"
              label="name"
              :placeholder="'Select Tag Type'"
              @input="resetSelectedTags"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="selectedTagSet"
          sm="12"
          class="mb-1"
        >
          <b-form-group
            v-if="selectedTagSet.select_type == 'Multiple'"
            label="Tags"
          >
            <v-select
              v-model="selectedTagNames"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="selectedTagSet.tags"
              :clearable="false"
              label="name"
              multiple
              :placeholder="'Select Tags'"
              @option:selecting="addTagName"
              @option:deselecting="removeTag"
            />
          </b-form-group>

          <b-form-group
            v-if="selectedTagSet.select_type == 'Radio'"
            label="Tags"
          >
            <template
              v-for="tag in selectedTagSet.tags"
            >
              <b-form-radio
                :key="tag.id"
                v-model="selectedTagNames"
                :value="tag"
                name="radio-options"
                @change="addTagName"
              > {{ tag.name }}
              </b-form-radio>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BFormTag,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useProgramTagsSetting from '@/views/admin/program/useProgramTagsSetting'
import store from '@/store'

export default {
  name: 'SetupStatusTagsModal',
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BFormTag,
    BFormRadio,
    vSelect,
  },
  data() {
    return {
      selectedTags: [],
      tagSetsList: [],
      selectedTagSet: {},
      selectedTagNames: null,
      isLoading: false,
      totalLength: 0,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'app-program/getProgramId',
      selectedStatus: 'app-program/getSelectedStatus',
    }),
    title() {
      if (this.selectedStatus) {
        return `Tag assign for applications with status ${this.selectedStatus.name}`
      }
      return 'Tag assign for applications'
    },
  },
  setup() {
    const {
      fetchTagSetsList,
      fetchTagAssignSettings,
      saveTagAssignSettings,
      fetchApplicationsForTagAutoAssign,
    } = useProgramTagsSetting()

    return {
      fetchTagSetsList,
      fetchTagAssignSettings,
      saveTagAssignSettings,
      fetchApplicationsForTagAutoAssign,
    }
  },
  methods: {
    resetModalData() {
      this.selectedTags = []
      this.selectedTagSet = {}
      this.isLoading = false
    },
    async setModalData() {
      this.isLoading = true
      await this.prepareTagSetsList()
      await this.setTagAssignSettingsData()
      store.commit('app-program/SET_TAGS_IDS_LIST_FOR_AUTO_ASSIGN', [])
      store.commit('app-program/SET_APPLICATIONS_FOR_TAG_AUTO_ASSIGN', [])
      this.isLoading = false
    },
    async prepareTagSetsList() {
      const queryParam = {
        hasTags: true,
        program_id: this.programId,
      }
      this.tagSetsList = await this.fetchTagSetsList(queryParam)
    },
    async setTagAssignSettingsData() {
      const queryParams = {
        programId: this.programId,
        statusId: this.selectedStatus.id,
      }
      await this.fetchTagAssignSettings(queryParams)
        .then(response => {
          this.selectedTags = response.map(item => item.tag)
        })
    },
    removeTag(tag) {
      const index = this.selectedTags.findIndex(item => item.id === tag.id)
      this.selectedTags.splice(index, 1)
    },
    resetSelectedTags() {
      this.selectedTagNames = null
      if (this.selectedTagSet.select_type === 'Radio') {
        this.selectedTagNames = this.selectedTagSet.tags.find(tag => this.selectedTags.some(selectedTag => tag.id === selectedTag.id))
      } else if (this.selectedTagSet.select_type === 'Multiple') {
        this.selectedTagNames = this.selectedTagSet.tags.filter(tag => this.selectedTags.find(selectedTag => tag.id === selectedTag.id))
      }
    },
    addTagName(tag) {
      if (this.selectedTagSet.select_type === 'Radio') {
        const oldTag = this.selectedTags.find(item => item.tag_set_id === (this.selectedTagSet.id))
        if (oldTag) {
          this.removeTag(oldTag)
        }
      }
      if (!this.selectedTags.some(item => item.id === tag.id)) {
        this.selectedTags.push(tag)
      }
    },
    async postStatusTags() {
      const queryParams = {
        tag_ids: this.selectedTags.map(tag => tag.id),
        program_id: this.programId,
        status_id: this.selectedStatus.id,
      }
      if (this.programId) {
        queryParams.program_id = this.programId
      }
      this.isLoading = true

      await this.saveTagAssignSettings(queryParams)

      await this.prepareApplicationsForTagAutoAssign(queryParams)
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('setup-status-tags')
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async prepareApplicationsForTagAutoAssign(queryParams) {
      const applicationsForTagAutoAssign = await this.fetchApplicationsForTagAutoAssign(queryParams)

      store.commit('app-program/SET_TAGS_IDS_LIST_FOR_AUTO_ASSIGN', queryParams.tag_ids)
      store.commit('app-program/SET_APPLICATIONS_FOR_TAG_AUTO_ASSIGN', applicationsForTagAutoAssign)
    },
  },
}
</script>

<style scoped>

</style>
