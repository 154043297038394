



























import Vue from 'vue'

import EmailTemplateForm from '@/views/admin/emails/email-forms/EmailTemplateForm.vue'
import { IEmailTemplate } from '../types'
import { appEmailItem } from '@/store/modules/app-email-item'

export default Vue.extend({
  name: 'EmailTemplateCreateModal',
  components: {
    EmailTemplateForm,
  },
  computed: {
    emailTemplate() {
      return appEmailItem.getters.getEmailTemplate
    },
    isButtonDisabled() {
      return appEmailItem.getters.getIsButtonDisabled
    },
  },
  async created() {
    this.initCreateTemplateFormData()
  },
  methods: {
    initCreateTemplateFormData() {
      appEmailItem.mutations.RESET_EMAIL_TEMPLATE()
    },
    async createTemplate() {
      const response = await appEmailItem.actions.createTemplate()
      this.closeForm(response.data.data)
    },
    async closeForm(emailTemplate: IEmailTemplate) {
      this.$bvModal.hide('add-email-template')
      this.$emit('close-modal', emailTemplate)
    },
  },
})
