var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"add-tag-triggers",attrs:{"id":"add-tag-triggers","centered":"","size":"xl","title":"Add Tag Triggers","ok-title":"Save","cancel-variant":"outline-secondary","no-close-on-backdrop":"","ok-disabled":!_vm.canUpdateTagTriggers},on:{"show":_vm.loadData,"hidden":function($event){return _vm.$emit('reset')},"ok":_vm.postTagTriggers}},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.isLoading,"variant":"white","opacity":"1","rounded":"sm"}},[_c('validation-observer',{ref:"tagTriggers",attrs:{"tag":"form"}},[_c('b-row',[_vm._l((_vm.filteredTagTriggers),function(tagTriggerRow,index){return [_c('b-col',{key:("event-" + index),staticClass:"mb-1",attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Event"}},[_c('validation-provider',{attrs:{"name":"event","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.triggerEventsOptions,"placeholder":'Select Event',"clearable":false,"reduce":function (val) { return val.value; }},model:{value:(tagTriggerRow.event),callback:function ($$v) {_vm.$set(tagTriggerRow, "event", $$v)},expression:"tagTriggerRow.event"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{key:("tag-" + index),staticClass:"mb-1",attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Tag"}},[_c('validation-provider',{attrs:{"name":"tag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{class:{'is-invalid': errors.length > 0 },attrs:{"multiple":false,"options":_vm.tagSetOptionsList,"search-nested":"","default-expand-level":1,"disable-branch-nodes":true,"placeholder":"Select Tag"},model:{value:(tagTriggerRow.tag_id),callback:function ($$v) {_vm.$set(tagTriggerRow, "tag_id", $$v)},expression:"tagTriggerRow.tag_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{key:("trigger-" + index),staticClass:"mb-1",attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Trigger"}},[_c('validation-provider',{attrs:{"name":"trigger","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.triggerTypesOptions,"placeholder":'Select Trigger',"clearable":false,"reduce":function (val) { return val.value; }},model:{value:(tagTriggerRow.trigger),callback:function ($$v) {_vm.$set(tagTriggerRow, "trigger", $$v)},expression:"tagTriggerRow.trigger"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(tagTriggerRow.trigger === 'email' || tagTriggerRow.trigger == null)?_c('b-col',{key:("email-" + index),staticClass:"mb-1",attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Email Template"}},[_c('validation-provider',{attrs:{"name":"email template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.emailTemplatesOptions,"clearable":false,"placeholder":'Select Email Template',"value":"id","label":"name","reduce":function (val) { return val.id; }},model:{value:(tagTriggerRow.template_id),callback:function ($$v) {_vm.$set(tagTriggerRow, "template_id", $$v)},expression:"tagTriggerRow.template_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(tagTriggerRow.trigger === 'sms')?_c('b-col',{key:("sms-" + index),staticClass:"mb-1",attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Sms Template"}},[_c('validation-provider',{attrs:{"name":"sms template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.smsTemplatesOptions,"clearable":false,"placeholder":'Select Sms Template',"value":"id","label":"name","reduce":function (val) { return val.id; }},model:{value:(tagTriggerRow.template_id),callback:function ($$v) {_vm.$set(tagTriggerRow, "template_id", $$v)},expression:"tagTriggerRow.template_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(tagTriggerRow)?_c('b-col',{key:("recipient-" + index),staticClass:"mb-1",attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Recipient"}},[_c('validation-provider',{attrs:{"name":"recipient","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.recipientsOptions,"clearable":false,"placeholder":'Select Recipients',"reduce":function (val) { return val.value; }},model:{value:(tagTriggerRow.recipient_type),callback:function ($$v) {_vm.$set(tagTriggerRow, "recipient_type", $$v)},expression:"tagTriggerRow.recipient_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{key:("delete-" + index),staticClass:"align-self-center",attrs:{"sm":"1"}},[(_vm.canDeleteTagTriggers)?_c('SquareIcon',{staticClass:"cursor-pointer",attrs:{"size":"21","icon":"Trash2Icon"},on:{"onIconClick":function($event){return _vm.removeRow(tagTriggerRow.frontID)}}}):_vm._e()],1)]}),(_vm.programId)?_c('b-col',{attrs:{"sm":"3"}},[_c('b-button',{staticClass:"d-flex align-items-center justify-content-center w-100 mt-2",attrs:{"variant":"outline-primary","disabled":!_vm.canCreateTagTriggers},on:{"click":_vm.addNewTagTriggerRow}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon","size":"18"}}),_vm._v(" Add Trigger ")],1)],1):_c('span',[_vm._v(" Program should be created first ")])],2)],1),(_vm.validationErrors.length)?_c('div',{staticClass:"form-group"},_vm._l((_vm.validationErrors),function(validationError,index){return _c('p',{key:index,staticClass:"text-center text-danger"},[_vm._v(" "+_vm._s(validationError)+" ")])}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }