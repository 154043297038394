<template>
  <div>
    <b-card>
      <!-- form -->
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-between mb-2">
            <h3 class="mb-0 font-weight-normal">
              Fees
            </h3>
          </div>
        </b-col>

        <b-col cols="12">
          <b-card
            no-body
          >
            <b-table
              ref="refInvoiceListTable"
              :items="feesList"
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="position-relative"
            >
              <!-- Loader -->
              <template #table-busy>
                <TableSpinner />
              </template>

              <!-- Column: cost -->
              <template #cell(cost)="data">
                ${{ data.value }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">

                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="mx-auto p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="canApplyFee"
                    v-b-modal.confirmation
                    @click="manipulated = { feeId: data.item.id, action: 'student' }"
                  >
                    <span>Apply to all Students</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="canApplyFee"
                    v-b-modal.confirmation
                    @click="manipulated = { feeId: data.item.id, action: 'staff' }"
                  >
                    <span>Apply to all Staff</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="canApplyFee"
                    v-b-modal.group-selection-modal
                    @click="manipulated = { feeId: data.item.id, action: 'groups' }"
                  >
                    <span>Apply to groups</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="canEditFee"
                    v-b-modal.edit-fee-modal
                    @click="openEditFeeModal(data.item)"
                  >
                    <span>Edit Fee</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="canDeleteFee"
                    v-b-modal.confirmation
                    @click="manipulated = { feeId: data.item.id, action: 'delete' }"
                  >
                    <span>Delete Fee</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>

            <div class="mx-2 my-2">
              <b-card
                v-if="isFeeCreating"
                class="bg-light fees"
              >
                <template #header>
                  <h4
                    class="d-flex align-items-center mb-0"
                  >
                    Adding Fee
                  </h4>
                </template>

                <validation-observer
                  ref="createFee"
                  tag="form"
                >
                  <form
                    ref="form"
                  >
                    <b-row>
                      <b-col
                        sm="6"
                        class="my-1"
                      >
                        <b-form-group
                          label="Fee Name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="fee name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="newFee.name"
                              placeholder="Name"
                              :class="{'is-invalid': errors.length > 0 }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        sm="6"
                        class="my-1"
                      >
                        <b-form-group
                          label="Cost"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="fee cost"
                            rules="required"
                          >
                            <b-form-input
                              v-model="newFee.cost"
                              placeholder="Cost $"
                              type="number"
                              :class="{'is-invalid': errors.length > 0 }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </form>
                </validation-observer>

                <template #footer>
                  <b-row
                    class="float-right"
                  >
                    <b-button
                      variant="outline-secondary"
                      class="mr-2"
                      @click="cancelCreating"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      variant="primary"
                      class="mr-2"
                      @click="createFee"
                    >
                      <span>Apply</span>
                    </b-button>
                  </b-row>
                </template>
              </b-card>
              <b-row v-else>
                <b-col
                  cols="12"
                  class="my-1"
                >
                  <div class="d-flex justify-content-center">
                    <b-button
                      v-if="canCreateFee"
                      variant="outline-primary"
                      class="d-flex align-items-center justify-content-center px-5"
                      @click="isFeeCreating = true"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                        class="mr-1"
                      />
                      Add Fee
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>

          </b-card>
        </b-col>

      </b-row>
    </b-card>

    <!-- Confirmation -->
    <confirmation-modal
      :toggle-modal="confirmationModalHide"
      :title="'Are you sure'"
      :message="confirmMessage"
      @close-modal="confirmationModalHide = !confirmationModalHide"
      @confirm="confirm"
    />

    <!-- Edit Fee Modal -->
    <b-modal
      id="edit-fee-modal"
      ref="edit-fee-modal"
      cancel-variant="outline-secondary"
      centered
      title="Edit Fee"
      no-close-on-backdrop
      @close="closeEditFeeModal"
    >
      <validation-observer
        ref="editFee"
        tag="form"
      >
        <form
          ref="form"
        >
          <b-row>
            <b-col
              sm="6"
              class="my-1"
            >
              <b-form-group
                label="Fee Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fee name"
                  rules="required"
                >
                  <b-form-input
                    v-model="manipulated.name"
                    placeholder="Name"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              class="my-1"
            >
              <b-form-group
                label="Cost"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fee cost"
                  rules="required"
                >
                  <b-form-input
                    v-model="manipulated.cost"
                    placeholder="Cost $"
                    type="number"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </validation-observer>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          @click="closeEditFeeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="updateFee"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <!-- Group Selection Modal -->
    <b-modal
      id="group-selection-modal"
      ref="group-selection-modal"
      cancel-variant="outline-secondary"
      centered
      title="Group Selection"
      no-close-on-backdrop
      @close="closeGroupSelectionModal"
    >
      <validation-observer
        ref="groupSelection"
        tag="form"
      >
        <form
          ref="form"
        >
          <b-row>
            <b-col
              sm="12"
              class="my-1"
            >
              <b-form-group
                label="Group"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fee name"
                  rules="required"
                >
                  <v-select
                    v-model="selectedGroups"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="groupsListForSelect"
                    :clearable="false"
                    label="name"
                    value="id"
                    multiple
                    :placeholder="fieldGroupStatus"
                    :reduce="group => group.id"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </validation-observer>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          @click="closeGroupSelectionModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="selectGroups"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BButton, BFormInput, BFormGroup, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from '@/views/admin/program/programStoreModule'
import useProgramFeesSetting from '@/views/admin/program/useProgramFeesSetting'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'ProgramTabTasks',
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    TableSpinner,
    ConfirmationModal,
    vSelect,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      feesList: [],
      isLoading: false,
      isFeeCreating: false,
      newFee: {},
      manipulated: {},
      confirmationModalHide: false,
      fieldGroupStatus: 'Loading...',
      groupsListForSelect: [],
      selectedGroups: [],
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program-fees'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const {
      tableColumns,
      fetchFees,
      postFee,
      updFee,
      deleteFee,
      applyFee,
      fetchGroupsForSelect,
    } = useProgramFeesSetting()

    return {
      tableColumns,
      fetchFees,
      postFee,
      updFee,
      deleteFee,
      applyFee,
      fetchGroupsForSelect,
    }
  },
  computed: {
    confirmMessage() {
      let message = ''
      if (this.manipulated.action === 'delete') {
        message = 'Are you sure you want to delete this fee?'
      }

      if (this.manipulated.action === 'student') {
        message = 'Are you sure you want to apply this fee to all Students?'
      }

      if (this.manipulated.action === 'staff') {
        message = 'Are you sure you want to apply this fee to all Staff?'
      }

      if (this.manipulated.action === 'groups') {
        message = 'Are you sure you want to apply this fee to chosen groups?'
      }

      return message
    },
    canCreateFee() {
      return this.$can('create', permissionSubjects.Fee)
    },
    canEditFee() {
      return this.$can('update', permissionSubjects.Fee)
    },
    canDeleteFee() {
      return this.$can('delete', permissionSubjects.Fee)
    },
    canApplyFee() {
      return this.$can('apply', permissionSubjects.Fee)
    },
  },
  async created() {
    await this.prepareFeesList()
    await this.prepareGroupList()
  },
  methods: {
    async prepareFeesList() {
      const queryParams = {
        programId: this.programId,
      }

      await this.fetchFees(queryParams).then(response => {
        this.feesList = response
      })
    },
    async prepareGroupList() {
      const queryParam = {
        program_id: this.programId,
        exceptProgramType: 'Program',
      }
      await this.fetchGroupsForSelect(queryParam)
        .then(response => {
          this.groupsListForSelect = response
          this.fieldGroupStatus = 'Select'
        })
    },
    cancelCreating() {
      this.isFeeCreating = false
      this.newFee = {}
    },
    createFee() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.createFee.validate().then(success => {
          if (success) {
            this.newFee.program_id = this.programId

            this.postFee(this.newFee)
              .then(response => {
                this.cancelCreating()
                this.prepareFeesList()
                return resolve(response)
              })
          } else {
            reject()
          }
        })
      })
    },
    updateFee() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.editFee.validate().then(success => {
          if (success) {
            this.updFee(this.manipulated)
              .then(response => {
                this.closeEditFeeModal()
                this.prepareFeesList()
                return resolve(response)
              })
          } else {
            reject()
          }
        })
      })
    },
    async applyFeeByType(type) {
      const queryParams = {
        fee_id: this.manipulated.feeId,
        program_id: this.programId,
      }

      if (type === 'groups') {
        queryParams.group_id = this.selectedGroups
      } else {
        queryParams.user_role = type
      }

      await this.applyFee(queryParams)

      this.closeGroupSelectionModal()
    },
    async confirm() {
      if (this.manipulated.action === 'delete') {
        await this.deleteFee(this.manipulated.feeId)
      } else {
        await this.applyFeeByType(this.manipulated.action)
      }

      this.confirmationModalHide = !this.confirmationModalHide
      this.manipulated = {}
      await this.prepareFeesList()
    },
    openEditFeeModal(item) {
      this.manipulated = {
        id: item.id,
        name: item.name,
        cost: item.cost,
      }
      this.$refs['edit-fee-modal'].show()
    },
    closeEditFeeModal() {
      this.$refs['edit-fee-modal'].hide()
      this.manipulated = {}
    },
    closeGroupSelectionModal() {
      this.$refs['group-selection-modal'].hide()
      this.manipulated = {}
      this.selectedGroups = []
    },
    selectGroups() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.groupSelection.validate().then(success => {
          if (success) {
            this.confirmationModalHide = !this.confirmationModalHide
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.fees {
  .card-header {
    border-bottom: 1px solid #dae1e7
  }
}

hr {
  border-top-width: 2px;
}

.media-item {
  position: relative;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
