<template>
  <div>
    <b-card>
      <!-- form -->
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-between mb-2">
            <h3 class="mb-0 font-weight-normal">
              Wallets
            </h3>
          </div>
        </b-col>

        <b-col cols="12">
          <b-card
            no-body
          >
            <b-table
              :items="walletsList"
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="position-relative"
            >

              <!-- Column: Active -->
              <template #cell(is_active)="data">
                <b-badge
                  :variant="`light-${resolveStatusVariant(data.item.is_active)}`"
                >
                  {{ data.item.is_active ? 'Active' : 'Inactive' }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">

                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="mx-auto p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="canUpdateWallet"
                    v-b-modal.edit-wallet-modal
                    @click="openEditWalletModal(data.item)"
                  >
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="canDeleteWallet"
                    @click="deleteWallet(data.item.id)"
                  >
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>

            <div class="mx-2 my-2">
              <b-card
                v-if="isWalletCreating"
                class="bg-light wallets"
              >
                <template #header>
                  <h4
                    class="d-flex justify-content-between align-items-center mb-0 w-100"
                  >
                    <span>Adding Wallet</span>
                    <span
                      class="d-flex align-items-center"
                      style="fontSize: 15px"
                    >
                      Active
                      <b-form-checkbox
                        v-model="newWallet.is_active"
                        switch
                        inline
                        class="ml-1"
                        :value="true"
                      />
                    </span>
                  </h4>
                </template>

                <validation-observer
                  ref="createWallet"
                  tag="form"
                >
                  <form
                    ref="form"
                  >
                    <b-row
                      class="my-1"
                    >
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          label="Wallet Name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Wallet name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="newWallet.name"
                              placeholder="Name"
                              :class="{'is-invalid': errors.length > 0 }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          label="Notes"
                        >
                          <b-form-textarea
                            v-model="newWallet.description"
                            placeholder="Add Wallet Notes"
                            rows="2"
                            no-resize
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </form>
                </validation-observer>

                <template #footer>
                  <b-row
                    class="float-right"
                  >
                    <b-button
                      variant="outline-secondary"
                      class="mr-2"
                      @click="cancelCreating"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      variant="primary"
                      class="mr-2"
                      @click="createWallet"
                    >
                      <span>Apply</span>
                    </b-button>
                  </b-row>
                </template>
              </b-card>

              <b-row v-else>
                <b-col
                  cols="12"
                  class="my-1"
                >
                  <div class="d-flex justify-content-center">
                    <b-button
                      v-if="canCreateWallet"
                      variant="outline-primary"
                      class="d-flex align-items-center justify-content-center px-5"
                      @click="isWalletCreating = true"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                        class="mr-1"
                      />
                      Add Wallet
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>

          </b-card>
        </b-col>

      </b-row>
    </b-card>

    <!-- Edit wallet Modal -->
    <b-modal
      id="edit-wallet-modal"
      ref="edit-wallet-modal"
      cancel-variant="outline-secondary"
      centered
      size="sm"
      title="Edit Wallet"
      no-close-on-backdrop
      @close="closeEditWalletModal"
    >
      <validation-observer
        ref="editWallet"
        tag="form"
      >
        <form
          ref="form"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Active"
              >
                <b-form-checkbox
                  v-model="selectedWallet.is_active"
                  switch
                  :value="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="my-1"
            >
              <b-form-group
                label="Wallet Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Wallet name"
                  rules="required"
                >
                  <b-form-input
                    v-model="selectedWallet.name"
                    placeholder="Name"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="my-1"
            >
              <b-form-group
                label="Notes"
              >
                <b-form-textarea
                  v-model="selectedWallet.description"
                  placeholder="Add Wallet Notes"
                  rows="2"
                  no-resize
                />
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </validation-observer>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          @click="closeEditWalletModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="updateWallet"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
} from 'bootstrap-vue'

import useProgramWalletsSetting from '@/views/admin/program/useProgramWalletsSetting'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'ProgramTabTasks',
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      walletsList: [],
      isWalletCreating: false,
      newWallet: {
        name: '',
        description: '',
        is_active: true,
      },
      selectedWallet: {},
    }
  },
  computed: {
    canCreateWallet() {
      return this.$can('create', permissionSubjects.Wallet)
    },
    canUpdateWallet() {
      return this.$can('update', permissionSubjects.Wallet)
    },
    canDeleteWallet() {
      return this.$can('delete', permissionSubjects.Wallet)
    },
  },
  setup() {
    const {
      tableColumns,
      fetchWallets,
      postWallet,
      updWallet,
      delWallet,
      resolveStatusVariant,
    } = useProgramWalletsSetting()

    return {
      tableColumns,
      fetchWallets,
      postWallet,
      updWallet,
      delWallet,
      resolveStatusVariant,
    }
  },
  async created() {
    await this.prepareWalletsList()
  },
  methods: {
    async prepareWalletsList() {
      const queryParams = {
        programId: this.programId,
      }

      await this.fetchWallets(queryParams).then(response => {
        this.walletsList = response
      })
    },
    cancelCreating() {
      this.isWalletCreating = false
      this.newWallet = {
        name: '',
        description: '',
        is_active: true,
      }
    },
    createWallet() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.createWallet.validate().then(success => {
          if (success) {
            this.newWallet.program_id = this.programId

            this.postWallet(this.newWallet)
              .then(response => {
                this.cancelCreating()
                this.prepareWalletsList()
                return resolve(response)
              })
          } else {
            reject()
          }
        })
      })
    },
    updateWallet() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.editWallet.validate().then(success => {
          if (success) {
            this.updWallet(this.selectedWallet)
              .then(response => {
                this.closeEditWalletModal()
                this.prepareWalletsList()
                return resolve(response)
              })
          } else {
            reject()
          }
        })
      })
    },
    openEditWalletModal(item) {
      this.selectedWallet = {
        id: item.id,
        name: item.name,
        description: item.description,
        is_active: item.is_active,
        program_id: this.programId,
      }
      this.$refs['edit-wallet-modal'].show()
    },
    closeEditWalletModal() {
      this.$refs['edit-wallet-modal'].hide()
      this.selectedWallet = {}
    },
    async deleteWallet(id) {
      await this.delWallet(id)
      this.prepareWalletsList()
    },
  },
}
</script>

<style lang="scss">
.wallets {
  .card-header {
    border-bottom: 1px solid #dae1e7
  }
}
.table-actions {
  width: 100px;
}
</style>
