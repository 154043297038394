<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <b-table
        ref="refInvoiceListTable"
        :items="communicationSettingsList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-badge
            variant="light-secondary"
          >
            {{ data.item.userEvent.name }}
          </b-badge>
        </template>

        <!-- Column: receiver -->
        <template #cell(receiver)="data">
          <b-badge
            variant="light-secondary"
          >
            {{ data.item.role.display_name }}
          </b-badge>
        </template>

        <!-- Column: template -->
        <template #cell(template)="data">
          <v-select
            v-model="data.item.emailTemplate.id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="allEmailTemplatesList"
            :clearable="false"
            :placeholder="'Select Email Template'"
            :reduce="val => val.id"
            @input="val => handleChosenEmailTemplate(data.index, val)"
          />
        </template>

        <!-- Column: actions -->
        <template #cell(actions)="data">
          <feather-icon
            v-if="data.item.emailTemplate.id"
            size="18"
            class="cursor-pointer"
            :icon="'XIcon'"
            @click="data.item.emailTemplate.id = null"
          />
        </template>

      </b-table>

    </b-card>

    <email-template-create-modal
      @close-modal="closeEmailTemplateCreateModal"
    />

  </div>

</template>

<script>
import {
  BCard, BTable, BBadge,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import store from '@/store'
import programStoreModule from '@/views/admin/program/programStoreModule'
import useProgramCommunicationSettingsList from '@/views/admin/program/program-communication-list/useProgramCommunicationList'
import EmailTemplateCreateModal from '@/views/admin/emails/email-forms/EmailTemplateCreateModal.vue'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    vSelect,
    EmailTemplateCreateModal,
  },
  props: {
    communicationSettingsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allEmailTemplatesList: [],
      newTemplateKey: 'add-new-template',
      editableObjectIndex: null,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-communication-program'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, programStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      tableColumns,
    } = useProgramCommunicationSettingsList()

    return {
      tableColumns,
    }
  },
  async created() {
    await this.fetchEmailTemplatesList()
  },
  methods: {
    async fetchEmailTemplatesList() {
      this.allEmailTemplatesList = await this.$store.dispatch('app-communication-program/fetchAllEmailTemplatesList')
        .then(response => response.data.data.reduce((arr, item) => {
          arr.push({ id: item.id, label: item.name })
          return arr
        }, []))

      this.allEmailTemplatesList.push({ id: this.newTemplateKey, label: 'Add New' })
    },
    async handleChosenEmailTemplate(index, val) {
      this.editableObjectIndex = index

      if (val === this.newTemplateKey) {
        this.$bvModal.show('add-email-template')
      }
    },
    async closeEmailTemplateCreateModal(createdEmailTemplate) {
      let value = null

      if (createdEmailTemplate !== undefined && createdEmailTemplate.id) {
        value = createdEmailTemplate.id
        await this.fetchEmailTemplatesList()
      }

      this.communicationSettingsList[this.editableObjectIndex].emailTemplate.id = value
      this.editableObjectIndex = null
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }
</style>
