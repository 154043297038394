<template>
  <b-card>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="0.5"
      rounded="sm"
    >
    <validation-observer
      ref="userRules"
      tag="form"
    >
      <!-- form -->
      <b-form>
        <b-row>
          <b-col cols="12">
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-0 font-weight-normal">
                Communications
              </h3>

              <!-- Dropdown -->
              <b-dropdown
                text="Triggers"
                variant="outline-primary"
                :disabled="!programId"
              >
                <b-dropdown-item
                  v-if="canReadApplicationTriggers"
                  @click="showTriggersModal"
                >
                  <span>General Triggers</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canReadTagTriggers"
                  @click="showTagTriggersModal"
                >
                  <span>Tag Triggers</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="From Email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  v-model="userData.email"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="From Name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  v-model="userData.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <ProgramCommunicationList
              :communication-settings-list="communicationSettingsList"
            />
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-2 mr-1"
              @click="handleForm"
            >
              Save changes
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              :to="{ name: 'admin-program'}"
              @click.prevent="resetForm"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    </b-overlay>
    <triggers-settings-modal
      v-if="programId"
      :program-id="programId"
    />

    <tag-triggers-settings-modal
      v-if="programId"
      :program-id="programId"
    />

  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ProgramCommunicationList from '@/views/admin/program/program-communication-list/ProgramCommunicationList.vue'
import TriggersSettingsModal from '@/views/admin/program/program-communication-list/TriggersSettingsModal.vue'
import TagTriggersSettingsModal from '@/views/admin/program/program-communication-list/TagTriggersSettingsModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,

    ProgramCommunicationList,
    TriggersSettingsModal,
    TagTriggersSettingsModal,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    communicationSettingsList: {
      type: Array,
      default: () => [],
    },
    programId: {
      type: String,
      default: null,
    },
  },
  computed: {
    canReadApplicationTriggers() {
      return this.$can('read', permissionSubjects.ApplicationTriggers)
    },
    canReadTagTriggers() {
      return this.$can('read', permissionSubjects.TagTriggers)
    },
    isLoading: {
      get() {
        return this.$store.getters['app-program/getIsLoading']
      },
      set(val) {
        this.$store.commit('app-program/SET_IS_LOADING', val)
      },
    },
  },
  methods: {
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.userRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('createProgram')
          } else {
            window.scrollTo(0, 0)
            reject()
          }
        })
      })
    },
    async showTriggersModal() {
      this.$bvModal.show('add-triggers')
    },
    async showTagTriggersModal() {
      this.$bvModal.show('add-tag-triggers')
    },
  },
}
</script>
