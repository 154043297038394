export const setActiveTab = {
  methods: {
    setTabIndexQuery(tabIndex) {
      const query = { ...this.$route.query }
      if (query.tab_index === tabIndex) return
      query.tab_index = tabIndex
      this.$router.push({ name: this.$router.currentRoute.name, query }).catch(() => {})
    },
    setActiveTab() {
      if (this.$router.currentRoute.query) {
        this.$nextTick(() => {
          this.tabIndex = Number(this.$router.currentRoute.query.tab_index)
        })
      }
    },
  },
}

export const _ = null
