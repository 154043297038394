import axios from '@axios'
import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { can } from '@core/libs/acl/utils'
import { permissionSubjects } from '@/libs/acl/constants'

import store from '@/store'
import { DEFAULT_PER_PAGE } from '@/constants/base'

export default function useProgramWalletsSetting() {
  // Use toast
  const toast = useToast()

  const canCreateTagType = computed(() => can('create', permissionSubjects.TagType))
  const canUpdateTagType = computed(() => can('update', permissionSubjects.TagType))
  const canDeleteTagType = computed(() => can('delete', permissionSubjects.TagType))

  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])
  const refProgramTagsTable = ref(null)
  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Tag Type', sortable: false },
    { key: 'select_type', label: 'Radio/Multi', sortable: false },
    { key: 'tags', label: 'Tag list', sortable: false },
    { key: 'actions', class: 'table-actions' },
  ]
  const tableItems = ref([])
  const isLoading = ref(false)
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)

  const refetchData = () => {
    refProgramTagsTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    // eslint-disable-next-line no-use-before-define
    fetchTagSets()
  })

  const dataMeta = computed(() => {
    const localItemsCount = refProgramTagsTable.value ? refProgramTagsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const fetchTagSets = () => {
    const queryParams = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      programId: programId.value,
    }
    isLoading.value = true
    axios.get('/auth/tag-sets', { params: queryParams })
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        // callback(data)
        totalItems.value = total
        tableItems.value = data
        isLoading.value = false
      })
      .catch(() => {
        isLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching tag sets',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const postTagSet = queryParams => axios
    .post('/auth/tag-sets', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Tag Set was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      if (error.response.status === 422) {
        const message = Object.values(error.response.data.errors)
        toast({
          component: ToastificationContent,
          props: {
            title: message.flat().join(','),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error creating Tag Set',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      throw new Error()
    })

  const updTagSet = queryParams => axios
    .put(`/auth/tag-sets/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Tag Set was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      if (error.response.status === 422) {
        const message = Object.values(error.response.data.errors)
        toast({
          component: ToastificationContent,
          props: {
            title: message.flat().join(','),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating Tag Set',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      throw new Error()
    })

  const delTagSet = id => axios
    .delete(`/auth/tag-sets/${id}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Tag Set was successfully deleted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Tag Set',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      throw new Error()
    })

  const delTag = id => axios
    .delete(`/auth/tags/${id}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Tag was successfully deleted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Tag',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      throw new Error()
    })

  const fetchTagSetsList = queryParams => axios
    .get('auth/tag-sets', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Tag Sets',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchTagAssignSettings = queryParams => axios
    .get('auth/tag-assign-settings', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Tag Assign Settings',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const saveTagAssignSettings = queryParams => axios
    .post('/auth/tag-assign-settings/save', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Tag Assign Settings was successfully saved',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error saving',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const fetchApplicationsForTagAutoAssign = queryParams => axios
    .get('auth/applications-for-tag-auto-assign', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Applications for tags auto assign',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  return {
    programId,
    refProgramTagsTable,
    tableColumns,
    tableItems,
    isLoading,
    perPage,
    totalItems,
    currentPage,
    sortBy,
    isSortDirDesc,
    dataMeta,
    refetchData,
    fetchTagSets,
    postTagSet,
    updTagSet,
    delTagSet,
    delTag,
    fetchTagSetsList,
    fetchTagAssignSettings,
    saveTagAssignSettings,
    fetchApplicationsForTagAutoAssign,
    canCreateTagType,
    canUpdateTagType,
    canDeleteTagType,
  }
}
