<template>
  <ValidationObserver
    ref="programApplicationSharingRules"
  >
    <b-modal
      id="program-application-sharing-modal"
      ref="program-application-sharing-modal"
      centered
      title="Application Sharing"
      no-close-on-backdrop
      @hidden="closeModal"
      @show="setModalData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Access Settings"
            >
              <validation-provider
                #default="{ errors }"
                name="Access Settings"
                rules="required"
              >
                <v-select
                  v-model="applicationSharingSettings.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="accessSettingsOptions"
                  :clearable="false"
                  :reduce="val => val.value"
                  :placeholder="'Select'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="applicationSharingSettings.type == 'only_selected_accounts'"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Accounts with Access"
            >
              <validation-provider
                #default="{ errors }"
                name="Accounts with Access"
                rules="required"
              >
                <v-select
                  v-model="applicationSharingSettings.selectedAccounts"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="accountsList"
                  :clearable="false"
                  label="full_name"
                  :reduce="account => account.id"
                  :placeholder="accountsFieldStatus"
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            @click="copyLink"
          >
            Copy Link
          </b-button>
          <b-button
            variant="primary"
            class="float-right"
            :disabled="isLoading"
            @click="handleForm"
          >
            Done
          </b-button>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useProgramApplicationSharingModal from '@/views/components/program-application-sharing-modal/useProgramApplicationSharingModal'

export default {
  name: 'ProgramApplicationSharingModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    sharingSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      applicationSharingSettings: {
        type: null,
        selectedAccounts: [],
        link: '',
      },
      accessSettingsOptions: [
        {
          label: 'Anyone with the link',
          value: 'enable_with_link',
        },
        {
          label: 'Only Accounts with access',
          value: 'only_selected_accounts',
        },
      ],
      accountsList: [],
      accountsFieldStatus: 'Loading...',
      required,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
  },
  setup() {
    const {
      fetchUsers,
    } = useProgramApplicationSharingModal()

    return {
      fetchUsers,
    }
  },
  async created() {
    await this.setUsersList()
  },
  methods: {
    closeModal() {
      this.applicationSharingSettings = {
        type: null,
        selectedAccounts: [],
        link: '',
      }
      this.$bvModal.hide('program-application-sharing-modal')
    },
    setModalData() {
      this.applicationSharingSettings = JSON.parse(JSON.stringify(this.sharingSettings))
    },
    async setUsersList() {
      try {
        this.isLoading = true
        // const queryParams = {
        //   programId: this.programId,
        // }
        // this.accountsList = await this.fetchUsers(queryParams)
        this.accountsList = await this.fetchUsers()
        this.accountsFieldStatus = 'Select'
      } finally {
        this.isLoading = false
      }
    },
    handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.programApplicationSharingRules.validate().then(success => {
          if (success) {
            this.$emit('saveChanges', this.applicationSharingSettings)
            this.$bvModal.hide('program-application-sharing-modal')
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    copyLink() {
      if (this.applicationSharingSettings.link) {
        navigator.clipboard.writeText(this.applicationSharingSettings.link).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Link copied',
              icon: 'BellIcon',
            },
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy! The link is empty',
            variant: 'danger',
            icon: 'BellIcon',
          },
        })
      }
    },
  },
}
</script>
