import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProgramWalletsSetting() {
  // Use toast
  const toast = useToast()
  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Wallet Name', sortable: false },
    { key: 'description', label: 'Notes', sortable: false },
    { key: 'is_active', label: 'Active', sortable: false },
    { key: 'actions', class: 'table-actions' },
  ]

  const fetchWallets = queryParams => axios
    .get('/auth/wallets', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching data',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const postWallet = queryParams => axios
    .post('/auth/wallets', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Wallet was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating Wallet',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })

  const updWallet = queryParams => axios
    .put(`/auth/wallets/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Wallet was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error updating Wallet',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const delWallet = id => axios
    .delete(`/auth/wallets/${id}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Wallet was successfully deleted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Wallet',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const resolveStatusVariant = status => (status ? 'success' : 'danger')

  return {
    tableColumns,
    fetchWallets,
    postWallet,
    updWallet,
    delWallet,
    resolveStatusVariant,
  }
}
