<template>
  <div>
    <b-card>
      <!-- form -->
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-between mb-2">
            <h3 class="mb-0 font-weight-normal">
              Tags
            </h3>
          </div>
        </b-col>

        <b-col cols="12">
          <b-card
            no-body
          >
            <b-table
              ref="refProgramTagsTable"
              :items="tableItems"
              :fields="tableColumns"
              :busy="isLoading"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="position-relative"
            >
              <!-- Loader -->
              <template #table-busy>
                <TableSpinner />
              </template>

              <template #row-details="data">
                <TagSet
                  :item="data.item"
                  :index="data.index"
                  :mode="'edit'"
                  @cancelEditing="data.toggleDetails"
                  @updateEditedTagSet="updateEditedTagSet"
                  @refetchData="fetchTagSets"
                />
              </template>

              <template #cell(name)="data">
                <feather-icon
                  v-if="!data.item.is_hidden"
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body mr-1"
                  @click="updateTagSetVisibility(data.index, data.item)"
                />
                <feather-icon
                  v-else
                  icon="EyeOffIcon"
                  size="16"
                  class="align-middle text-body mr-1 text-muted"
                  @click="updateTagSetVisibility(data.index, data.item)"
                />
                <span :style="{color: data.item.color}">{{ data.value }}</span>
              </template>

              <template #cell(tags)="data">
                <b-form-tag
                  v-for="(tag, tagIndex) in data.item.tags"
                  :key="tag.id"
                  :style="{
                    backgroundColor: '#F3F2F7',
                    color: tag.color,
                  }"
                  :no-remove="!canUpdateTagType"
                  class="mr-1 mb-1"
                  @remove="deleteTag(data.index, tagIndex, tag.id)"
                >{{ tag.name }}</b-form-tag>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">

                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="mx-auto p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="canUpdateTagType"
                    @click="data.toggleDetails"
                  >
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="canDeleteTagType"
                    @click="deleteTagSet(data.index, data.item.id)"
                  >
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>

            <div class="mx-2 my-2">
              <TagSet
                v-if="isTagTypeCreating"
                @cancelCreating="isTagTypeCreating = false"
                @addNewTagSet="addNewTagSet"
                @refetchData="fetchTagSets"
              />

              <b-row v-else>
                <b-col
                  cols="12"
                  class="my-1"
                >
                  <div class="d-flex justify-content-center">
                    <b-button
                      variant="outline-primary"
                      class="d-flex align-items-center justify-content-center px-5"
                      :disabled="!canCreateTagType"
                      @click="isTagTypeCreating = true"
                    >
                      Add Tag Type
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                        class="ml-1"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2 mb-2 border-top">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-if="totalItems && perPage !== 'all'"
                    v-model="currentPage"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>

      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BButton, BDropdown, BDropdownItem, BPagination, BFormTag,
} from 'bootstrap-vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import TagSet from '@/views/components/tag-set/TagSet.vue'

import useProgramTagsSetting from '@/views/admin/program/useProgramTagsSetting'

export default {
  name: 'ProgramTabTags',
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTag,

    TableSpinner,
    TagSet,
  },
  data() {
    return {
      isTagTypeCreating: false,
    }
  },
  setup() {
    const {
      programId,
      refProgramTagsTable,
      tableColumns,
      tableItems,
      isLoading,
      perPage,
      totalItems,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,
      refetchData,
      fetchTagSets,
      updTagSet,
      delTagSet,
      delTag,
      canCreateTagType,
      canUpdateTagType,
      canDeleteTagType,
    } = useProgramTagsSetting()

    return {
      programId,
      refProgramTagsTable,
      tableColumns,
      tableItems,
      isLoading,
      perPage,
      totalItems,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,
      refetchData,
      fetchTagSets,
      updTagSet,
      delTagSet,
      delTag,
      canCreateTagType,
      canUpdateTagType,
      canDeleteTagType,
    }
  },
  async mounted() {
    this.fetchTagSets()
  },
  methods: {
    async deleteTagSet(index, id) {
      try {
        await this.delTagSet(id)
        this.tableItems.splice(index, 1)
        this.totalItems -= 1
      } catch (error) {
        this.fetchTagSets()
      }
    },
    async deleteTag(rowIndex, tagIndex, tagId) {
      try {
        await this.delTag(tagId)
        this.tableItems[rowIndex].tags.splice(tagIndex, 1)
      } catch (error) {
        this.fetchTagSets()
      }
    },
    async updateTagSetVisibility(rowIndex, item) {
      try {
        const queryParams = {
          ...item,
          is_hidden: !item.is_hidden,
          program_id: this.programId,
        }
        this.tableItems[rowIndex].is_hidden = !item.is_hidden
        await this.updTagSet(queryParams)
      } catch (error) {
        this.fetchTagSets()
      }
    },
    updateEditedTagSet({ rowIndex, data }) {
      this.$set(this.tableItems, rowIndex, data)
    },
    addNewTagSet(data) {
      if (this.tableItems.length === this.perPage) {
        this.tableItems.pop()
      }
      this.tableItems.unshift(data)
      this.totalItems += 1
    },
  },
}
</script>

<style lang="scss">
.table-actions {
  width: 100px;
}
</style>
