import axios from '@axios'
import { computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { can } from '@core/libs/acl/utils'
import { permissionSubjects } from '@/libs/acl/constants'

export default function useTriggersSetting() {
  // Use toast
  const toast = useToast()

  const canCreateApplicationTriggers = computed(() => can('create', permissionSubjects.ApplicationTriggers))
  const canUpdateApplicationTriggers = computed(() => can('update', permissionSubjects.ApplicationTriggers))
  const canDeleteApplicationTriggers = computed(() => can('delete', permissionSubjects.ApplicationTriggers))
  const canCreateTagTriggers = computed(() => can('create', permissionSubjects.TagTriggers))
  const canUpdateTagTriggers = computed(() => can('update', permissionSubjects.TagTriggers))
  const canDeleteTagTriggers = computed(() => can('delete', permissionSubjects.TagTriggers))

  const fetchStatuses = queryParams => axios
    .get('/auth/status', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchSteps = queryParams => axios
    .get('/auth/steps', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchEvents = () => axios
    .get('/auth/trigger/communications/events')
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchTriggers = () => axios
    .get('/auth/trigger/communications/triggers')
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchEmailTemplates = () => axios
    .get('/auth/email-templates')
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchSmsTemplates = () => axios
    .get('/auth/sms-templates')
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchProgramTriggers = queryParams => axios
    .get('/auth/trigger/communications', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const saveTriggers = queryParams => axios
    .post('/auth/trigger/communications/save', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Triggers was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error saving',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const fetchTagTriggers = queryParams => axios
    .get('/auth/tag-triggers', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const saveTagTriggers = queryParams => axios
    .post('/auth/tag-triggers/save', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Tag Triggers was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error saving',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const fetchProgramTags = queryParams => axios
    .get('/auth/tags', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const prepareTagSetsListOptions = queryParams => axios
    .get('/auth/tag-sets', { params: queryParams })
    .then(response => response.data.data.reduce((array, tagSet) => {
      const children = tagSet.tags.reduce((arr, tag) => {
        arr.push({
          id: tag.id, label: tag.name, tagSetId: tagSet.id, selectType: tagSet.select_type,
        })
        return arr
      }, [])
      array.push({
        id: `tag_set-${tagSet.id}`, label: tagSet.name, selectType: tagSet.select_type, children,
      })
      return array
    }, []))
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchTagTriggerEvents = () => axios
    .get('/auth/tag-triggers/events')
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchTagTriggerTypes = () => axios
    .get('/auth/tag-triggers/types')
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchPpaUniqueFields = queryParams => axios
    .get('/auth/unique-fields', { params: queryParams })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching attribute handlers',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchStatuses,
    fetchProgramTriggers,
    fetchEmailTemplates,
    fetchSteps,
    fetchEvents,
    fetchTriggers,
    saveTriggers,
    fetchTagTriggers,
    fetchSmsTemplates,
    saveTagTriggers,
    fetchProgramTags,
    prepareTagSetsListOptions,
    fetchTagTriggerEvents,
    fetchTagTriggerTypes,
    fetchPpaUniqueFields,
    canCreateApplicationTriggers,
    canUpdateApplicationTriggers,
    canDeleteApplicationTriggers,
    canCreateTagTriggers,
    canUpdateTagTriggers,
    canDeleteTagTriggers,
  }
}
