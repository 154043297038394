import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProgramApplicationSharingModal() {
  // Use toast
  const toast = useToast()

  const fetchUsers = queryParams => axios
    .get('auth/users', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Users',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchUsers,
  }
}
