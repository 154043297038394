export default function useProgramCommunicationSettingsList() {
  // Table Handlers
  const tableColumns = [
    {
      key: 'name', label: 'Name', sortable: true, thStyle: { width: '10%' },
    },
    {
      key: 'receiver', label: 'to', sortable: true, thStyle: { width: '10%' },
    },
    {
      key: 'template', label: 'template', sortable: false, thStyle: { width: '10%' },
    },
    {
      key: 'actions', label: '', sortable: false, thStyle: { width: '3%' },
    },
  ]

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
  }
}
