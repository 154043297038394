var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"taskRules",attrs:{"tag":"form"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('h3',{staticClass:"mb-0 font-weight-normal"},[_vm._v(" Tasks ")])])])],1),_vm._l((_vm.tasks),function(task,index){return _c('b-row',{key:index},[(!task.delete)?[_c('b-col',{attrs:{"md":"11","xl":"11"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"7"}},[_c('b-form-group',{attrs:{"label":"Task Name"}},[_c('validation-provider',{attrs:{"name":'task name ' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"What needs to be done is written here","state":errors.length > 0 ? false:null},model:{value:(task.name),callback:function ($$v) {_vm.$set(task, "name", $$v)},expression:"task.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label":"Groups"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.groupsList,"clearable":false,"label":"name","value":"id","multiple":"","close-on-select":false,"placeholder":_vm.fieldGroupStatus,"reduce":function (group) { return group.id; }},model:{value:(task.group_ids),callback:function ($$v) {_vm.$set(task, "group_ids", $$v)},expression:"task.group_ids"}})],1)],1),_c('b-col',{attrs:{"sm":"7"}},[_c('b-form-group',{attrs:{"label":"Scope"}},[_c('validation-provider',{attrs:{"name":'task scope ' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.scopesList,"clearable":false,"label":"text","value":"value","reduce":function (scope) { return scope.value; },"placeholder":'Select Scope',"multiple":true,"state":errors.length > 0 ? false:null},model:{value:(task.scope),callback:function ($$v) {_vm.$set(task, "scope", $$v)},expression:"task.scope"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label":"Form"}},[_c('validation-provider',{attrs:{"name":'task form ' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allFormListForSelect,"clearable":false,"label":"name","value":"id","reduce":function (form) { return form.id; },"placeholder":_vm.fieldFormStatus,"state":errors.length > 0 ? false:null},model:{value:(task.form),callback:function ($$v) {_vm.$set(task, "form", $$v)},expression:"task.form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"7"}},[_c('b-form-group',{attrs:{"label":"Due date"}},[_c('validation-provider',{attrs:{"name":'task due date ' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null},model:{value:(task.date),callback:function ($$v) {_vm.$set(task, "date", $$v)},expression:"task.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"md":"1","xl":"1"}},[_c('SquareIcon',{staticClass:"cursor-pointer",attrs:{"size":"21","icon":"Trash2Icon"},on:{"onIconClick":function($event){return _vm.removeRow(task.frontID)}}})],1),_c('hr',{staticClass:"w-100 my-2"})]:_vm._e()],2)}),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();return _vm.addEmptyTask.apply(null, arguments)}}},[_vm._v(" Add Task ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":_vm.handleForm}},[_vm._v(" Save changes ")]),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset","to":{ name: 'admin-program'}}},[_vm._v(" Cancel ")])],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }