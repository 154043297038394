<template>
  <b-card>

    <!-- form -->
    <validation-observer
      ref="taskRules"
      tag="form"
    >
      <b-form>
        <b-row>

          <!-- title -->
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <h3 class="mb-0 font-weight-normal">
                Tasks
              </h3>
            </div>
          </b-col>

        </b-row>

        <b-row
          v-for="(task, index) in tasks"
          :key="index"
        >
          <template
            v-if="!task.delete"
          >
            <b-col
              md="11"
              xl="11"
            >
              <b-row>

                <b-col sm="7">
                  <b-form-group
                    label="Task Name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="'task name ' + index"
                      rules="required"
                    >
                      <b-form-input
                        v-model="task.name"
                        placeholder="What needs to be done is written here"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="5">
                  <b-form-group
                    label="Groups"
                  >
                    <v-select
                      v-model="task.group_ids"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="groupsList"
                      :clearable="false"
                      label="name"
                      value="id"
                      multiple
                      :close-on-select="false"
                      :placeholder="fieldGroupStatus"
                      :reduce="group => group.id"
                    />
                  </b-form-group>
                </b-col>

                <b-col sm="7">
                  <b-form-group
                    label="Scope"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="'task scope ' + index"
                      rules="required"
                    >
                      <v-select
                        v-model="task.scope"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="scopesList"
                        :clearable="false"
                        label="text"
                        value="value"
                        :reduce="scope => scope.value"
                        :placeholder="'Select Scope'"
                        :multiple="true"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="5">
                  <b-form-group
                    label="Form"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="'task form ' + index"
                      rules="required"
                    >
                      <v-select
                        v-model="task.form"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="allFormListForSelect"
                        :clearable="false"
                        label="name"
                        value="id"
                        :reduce="form => form.id"
                        :placeholder="fieldFormStatus"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="7">
                  <b-form-group
                    label="Due date"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="'task due date ' + index"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="task.date"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>

            <!-- Input Delete -->
            <b-col
              md="1"
              xl="1"
              class="d-flex justify-content-end align-items-center"
            >
              <SquareIcon
                size="21"
                icon="Trash2Icon"
                class="cursor-pointer"
                @onIconClick="removeRow(task.frontID)"
              />
            </b-col>

            <hr class="w-100 my-2">
          </template>
        </b-row>

        <b-button
          variant="outline-primary"
          @click.prevent="addEmptyTask"
        >
          Add Task
        </b-button>

        <!-- buttons -->
        <b-row>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-2 mr-1"
              :disabled="isLoading"
              @click="handleForm"
            >
              Save changes
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              :to="{ name: 'admin-program'}"
            >
              Cancel
            </b-button>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from '@/views/admin/program/programStoreModule'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'
import useProgramTasksSetting from '@/views/admin/program/useProgramTasksSetting'
import useNewTaskModal from '@/views/components/new-task-modal/useNewTaskModal'

export default {
  name: 'ProgramTabTasks',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    flatPickr,
    vSelect,

    SquareIcon,
  },
  props: {
    programId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      tasks: [],
      tasksCount: 0,
      allFormListForSelect: [],
      fieldFormStatus: 'Loading...',
      groupsList: [],
      fieldGroupStatus: 'Loading...',
      scopesList: [
        {
          value: 'Staff',
          text: 'Staff',
        },
        {
          value: 'Camper',
          text: this.camperStudent(1),
        },
      ],
      required,
      isLoading: false,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program-task'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const {
      saveTasks,
    } = useProgramTasksSetting()

    const {
      fetchGroups,
    } = useNewTaskModal()

    return {
      saveTasks,
      fetchGroups,
    }
  },
  async created() {
    await this.fetchForms()
    await this.fetchGroupsList()
    await this.fetchProgramTasks()
  },
  methods: {
    async fetchForms() {
      await this.$store.dispatch('app-program-task/fetchForms', {
        withoutProgramForms: true,
      })
        .then(response => {
          this.allFormListForSelect = response.data.data
          this.fieldFormStatus = 'Select Form'
        })
    },
    async fetchProgramTasks() {
      if (this.programId) {
        await this.$store.dispatch('app-program-task/fetchProgramTasks', {
          programId: this.programId,
          hasForm: true,
        })
          .then(response => {
            this.prepareTasksDataFromResponse(response.data.data)
          })
      } else {
        this.addEmptyTask()
      }
    },
    async fetchGroupsList() {
      const queryParam = {
        program_id: this.programId,
        exceptProgramType: 'Program',
      }
      this.groupsList = await this.fetchGroups(queryParam)
      this.fieldGroupStatus = 'Select Groups'
    },
    prepareTasksDataFromResponse(tasks) {
      if (tasks && tasks.length) {
        tasks.forEach(task => {
          this.tasksCount += 1
          this.tasks.push({
            id: task.id,
            name: task.name,
            date: task.date,
            scope: task.scope,
            form: task.form.id,
            group_ids: task.group_ids,
            frontID: this.tasksCount,
            delete: false,
          })
        })
      } else {
        this.addEmptyTask()
      }
    },
    addEmptyTask() {
      this.tasksCount += 1
      this.tasks.push({
        name: '',
        date: '',
        scope: '',
        form: '',
        group_ids: [],
        delete: false,
        frontID: this.tasksCount,
        newTask: true,
      })
    },
    removeRow(rowID) {
      const curItem = this.tasks.findIndex(item => item.frontID === rowID)
      this.tasks[curItem].delete = true

      if (this.tasks[curItem].newTask) {
        this.tasks.splice(curItem, 1)
      }
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.taskRules.validate().then(success => {
          if (success) {
            this.isLoading = true
            resolve(true)

            const formData = this.tasks.map(task => ({
              program_id: this.programId,
              form_id: task.form,
              ...task,
            }))

            this.saveTasks(formData)
              .then(response => {
                this.tasks = []
                this.prepareTasksDataFromResponse(response)
              })
              .finally(() => {
                this.isLoading = false
              })
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

hr {
  border-top-width: 2px;
}

.media-item {
  position: relative;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
