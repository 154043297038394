import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePaymentContractsSetting() {
  // Use toast
  const toast = useToast()

  const createPaymentContract = queryParams => axios
    .post('/auth/payment-contract', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Payment Contract was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating Payment Contract',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updatePaymentContract = queryParams => axios
    .put(`/auth/payment-contract/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Payment Contract was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Payment Contract',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchPaymentContract = (ID, queryParams) => axios
    .get(`/auth/payment-contract/${ID}`, { params: queryParams })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Payment Contract item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchPaymentContractList = () => axios
    .get('/auth/payment-contract/')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Payment Contract item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    createPaymentContract,
    updatePaymentContract,
    fetchPaymentContract,
    fetchPaymentContractList,
  }
}
