<template>
  <b-modal
    id="add-triggers"
    ref="add-triggers"
    centered
    title="Add Triggers"
    ok-title="Save"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    size="lg"
    :ok-disabled="!canUpdateApplicationTriggers"
    @show="loadData"
    @hidden="resetData"
    @ok="postTriggers"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="programTriggers"
        tag="form"
      >
        <template
          v-for="(triggerRow, index) in filteredTriggers"
        >
          <b-row
            :key="`trigger-row-${index}`"
            class="border-bottom my-2"
          >
            <b-col cols="11">
              <b-row>
                <!--  Registration option  -->
                <b-col
                  sm="4"
                  class="mb-1"
                >
                  <b-form-group
                    label="Registration option"
                  >
                    <v-select
                      v-model="triggerRow.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="registrationOptions"
                      :placeholder="'Registration option'"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <template v-if="triggerRow.type == 'Step'">
                  <!--  Step  -->
                  <b-col
                    sm="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Step"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="step"
                        rules="required"
                      >
                        <v-select
                          v-model="triggerRow.for_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="stepsList"
                          :placeholder="stepOptionPlaceholder"
                          :clearable="false"
                          value="id"
                          label="full_name"
                          :reduce="value => value.id"
                          :class="{'is-invalid': errors.length > 0 }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Event  -->
                  <b-col
                    sm="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Event"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="event"
                        rules="required"
                      >
                        <v-select
                          v-model="triggerRow.event_class"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="eventsOptions"
                          :placeholder="'Select Event'"
                          :clearable="false"
                          value="event"
                          label="name"
                          :reduce="value => value.event"
                          :class="{'is-invalid': errors.length > 0 }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </template>

                <template v-else>
                  <!--  Status (from)  -->
                  <b-col
                    sm="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Status (from)"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Status (from)"
                        rules="required"
                      >
                        <v-select
                          v-model="triggerRow.for_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusesOptions"
                          placeholder="Select Status"
                          :clearable="false"
                          value="id"
                          label="name"
                          :reduce="value => value.id"
                          :class="{'is-invalid': errors.length > 0 }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Status (to)  -->
                  <b-col
                    sm="4"
                    class="mb-1"
                  >
                    <b-form-group
                      label="Status (to)"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Status (to)"
                        rules="required"
                      >
                        <v-select
                          v-model="triggerRow.to_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusesOptions"
                          placeholder="Select Status"
                          :clearable="false"
                          value="id"
                          label="name"
                          :reduce="value => value.id"
                          :class="{'is-invalid': errors.length > 0 }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </template>

                <!--  Trigger  -->
                <b-col
                  sm="4"
                  class="mb-1"
                >
                  <b-form-group
                    label="Trigger"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="trigger"
                      rules="required"
                    >
                      <v-select
                        v-model="triggerRow.trigger"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="triggersOptions"
                        :placeholder="'Select Trigger'"
                        :clearable="false"
                        :reduce="value => value.value"
                        :class="{'is-invalid': errors.length > 0 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!--  Email Template  -->
                <b-col
                  v-if="triggerRow.trigger === 'Email' || triggerRow.trigger == null"
                  sm="4"
                  class="mb-1"
                >
                  <b-form-group
                    label="Email Template"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email template"
                      rules="required"
                    >
                      <v-select
                        v-model="triggerRow.email_template_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="emailTemplatesOptions"
                        :clearable="false"
                        :placeholder="'Select Email Template'"
                        value="id"
                        label="name"
                        :reduce="val => val.id"
                        :class="{'is-invalid': errors.length > 0 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Rich Text field  -->
                <b-col
                  v-else
                  sm="4"
                  class="mb-1"
                >
                  <b-form-group
                    label="Text"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="text"
                      rules="required"
                    >
                      <quill-editor
                        id="email-content"
                        v-model="triggerRow.message_text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Trigger Delete -->
            <b-col
              cols="1"
              class="align-self-center"
            >
              <SquareIcon
                v-if="canDeleteApplicationTriggers"
                size="21"
                icon="Trash2Icon"
                class="cursor-pointer"
                @onIconClick="removeRow(triggerRow.frontID)"
              />
            </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col
            v-if="programId"
            sm="4"
          >
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center justify-content-center w-100 mt-2"
              :disabled="!canCreateApplicationTriggers"
              @click="addNewTriggerRow"
            >
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="mr-1"
              />
              Add Trigger
            </b-button>
          </b-col>
          <span v-else>
            Program should be created first
          </span>
        </b-row>
      </validation-observer>
      <div
        v-if="validationErrors.length"
        class="form-group"
      >
        <p
          v-for="(validationError, index) in validationErrors"
          :key="index"
          class="text-center text-danger"
        >
          {{ validationError }}
        </p>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BButton,
  BFormGroup,
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'
import useTriggersSetting from '@/views/admin/program/program-communication-list/useTriggersSetting'

import store from '@/store'

export default {
  name: 'TriggersSettingsModal',
  components: {
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BOverlay,

    ValidationObserver,
    ValidationProvider,
    vSelect,
    SquareIcon,
    quillEditor,
  },
  props: {
    programId: {
      type: [String, null],
      required: true,
    },
  },
  data() {
    return {
      registrationOptions: ['Status', 'Step'],
      rowsCount: 0,
      triggersList: [],
      eventsOptions: [],
      emailTemplatesOptions: [],
      triggersOptions: [],
      validationErrors: [],
      stepOptionPlaceholder: 'Loading...',
      statusOptionPlaceholder: 'Loading...',
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      statusesOptions: 'app-program/getAllProgramStatusesList',
      stepsList: 'app-program/getAllProgramStepsList',
    }),
    filteredTriggers() {
      return this.triggersList.filter(trigger => !trigger.delete)
    },
  },
  setup() {
    const {
      fetchStatuses,
      fetchProgramTriggers,
      fetchSteps,
      fetchEvents,
      fetchTriggers,
      fetchEmailTemplates,
      saveTriggers,
      canCreateApplicationTriggers,
      canUpdateApplicationTriggers,
      canDeleteApplicationTriggers,
    } = useTriggersSetting()

    return {
      fetchStatuses,
      fetchProgramTriggers,
      fetchSteps,
      fetchEvents,
      fetchTriggers,
      fetchEmailTemplates,
      saveTriggers,
      canCreateApplicationTriggers,
      canUpdateApplicationTriggers,
      canDeleteApplicationTriggers,
    }
  },
  methods: {
    async loadData() {
      this.isLoading = true
      await store.dispatch('app-program/fetchAllProgramStatusesList')
      await this.setProgramTriggersData()
      await this.fetchEvents().then(response => {
        this.eventsOptions = response
      })
      await this.fetchTriggers().then(response => {
        this.triggersOptions = response.map(item => (
          {
            value: item,
            label: item,
          }
        ))
      })
      await this.fetchEmailTemplates().then(response => {
        this.emailTemplatesOptions = response
      })
      this.isLoading = false
    },
    async setProgramTriggersData() {
      await this.fetchProgramTriggers({ programId: this.programId })
        .then(response => {
          this.triggersList = response.map((item, index) => (
            {
              frontID: index,
              delete: false,
              ...item,
            }
          ))

          this.rowsCount = this.triggersList.length
        })
    },
    isFrontIDExists(key) {
      return this.triggersList.some(field => field.frontID === key)
    },
    addNewTriggerRow() {
      do {
        this.rowsCount += 1
      } while (this.isFrontIDExists(this.rowsCount))

      this.triggersList.push({
        id: null,
        program_id: this.programId,
        type: 'Status',
        event_class: null,
        for_id: null,
        to_id: null,
        trigger: null,
        email_template_id: null,
        delete: false,
        frontID: this.rowsCount,
        message_text: null,
        newField: true,
      })
    },
    removeRow(ID) {
      const curItem = this.triggersList.findIndex(item => item.frontID === ID)
      this.triggersList[curItem].delete = true

      if (this.triggersList[curItem].newField) {
        this.triggersList.splice(curItem, 1)
      }
    },
    resetData() {

    },
    async postTriggers(bvModalEvt) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise(reject => {
        this.$refs.programTriggers.validate().then(success => {
          if (success) {
            this.saveTriggers(this.triggersList)
              .then(response => {
                if (response.data && response.status === 422) {
                  const validationErrors = Object.values(response.data.errors)
                  this.validationErrors = validationErrors.flat()
                } else {
                  this.validationErrors = []
                  this.$nextTick(() => {
                    this.$refs['add-triggers'].toggle('#toggle-btn')
                  })
                }
              })
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
